import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addMembersData, getAllMembersData } from '../../redux/actions/memberActions';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableHead, TableRow, Stack, TextField } from '@mui/material';

const AddMember = ({ open, handleClose, card }) => {

    const dispatch = useDispatch();

    const membersData = useSelector((state) => state?.membersData?.memberdata?.data?.data);


    useEffect(() => {
        const fetchAllMembers = async () => {
            try {
                const data = await dispatch(getAllMembersData(card));
                console.log("Fetched members:", data);
            } catch (error) {
                console.error("Error fetching members:", error);
            }
        };

        if (card) {
            fetchAllMembers();
        }
    }, [dispatch, card]);

    const [changeMemberNameOpen, setChangeMemberNameOpen] = useState(false);
    const [memberId, setMemberId] = useState();
    const [memberName, setMemberName] = useState('');
    const [memberMobile, setMemberMobile] = useState();
    const [memberEmail, setMemberEmail] = useState('');
    const [memberPosition, setMemberPosition] = useState();
    const handleChangeMemberName = (member) => {
        setChangeMemberNameOpen(true);
        setMemberId(member?.user_id);
        setMemberPosition(member?.member_position);
    };

    const handleChangeMemberNameClose = () => {
        setChangeMemberNameOpen(false);
    };

    const handleAddMember = async () => {
        const data = { member_name: memberName, member_mobile: memberMobile, member_email: memberEmail, member_position: memberPosition }
        const response = await addMembersData(card, data);
        setMemberName('');
        setMemberMobile();
        setMemberEmail('');
        handleChangeMemberNameClose();
    };

    const handleMobileChange = (e, setState) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setState(value.slice(0, 10)); // Limit to 10 digits
    };
    var members = []
    if (membersData) {
        members = Object?.keys(membersData)?.map((key) => membersData[key]);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Add Members</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Mobile</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {members.map((member, index) => (
                                <TableRow key={index}>
                                    <TableCell>{member.name || 'N/A'}</TableCell>
                                    <TableCell>{member.mobile || 'N/A'}</TableCell>
                                    <TableCell>{member.role}</TableCell>
                                    <TableCell>
                                        {member.role !== 'owner' && (
                                            <Button variant="outlined" size="small"
                                                onClick={() => handleChangeMemberName(member)}
                                            >
                                                Change Name
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={changeMemberNameOpen} onClose={handleChangeMemberNameClose}>
                <DialogTitle>Change Members</DialogTitle>
                <DialogContent sx={{ marginTop: 2 }}>
                    <Stack spacing={2}>
                        <TextField
                            label="Member Name"
                            value={memberName}
                            onChange={(e) => setMemberName(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            placeholder='Member Name'
                        />
                        <TextField
                            label="Attendee WhatsApp Number Only*"
                            value={memberMobile}
                            onChange={(e) => handleMobileChange(e, setMemberMobile)}
                            fullWidth
                            margin="normal"
                            helperText={memberMobile?.length > 0 && memberMobile?.length !== 10 ? "Mobile number must be 10 digits" : ""}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Member Email"
                            value={memberEmail}
                            onChange={(e) => setMemberEmail(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            placeholder='Member Email'
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddMember}>Submit</Button>
                    <Button onClick={handleChangeMemberNameClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddMember