import React, { useState, useEffect } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function PaymentFailed() {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(60);

    useEffect(() => {
        if (timer === 0) {
            navigate('/login');
        }

        const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer, navigate]);

    return (
        <Container maxWidth='sm' style={{ textAlign: 'center', marginTop: '50px' }}>
            <ErrorOutlineIcon style={{ fontSize: 80, color: 'red' }} />
            <Typography variant='h4' style={{ marginTop: '20px' }}>
                Payment Failed
            </Typography>
            <Typography variant='body1' style={{ margin: '20px 0' }}>
                Your payment could not be processed, Please try again
            </Typography>
            <Typography variant='body1' style={{ margin: '20px 0' }}>
                Please use the same mobile number to login and view your card. Your card will be available in My Cards section. You can re-initiate the payment from My Cards Section. If there is any debit from your account, it will be refunded within 7 working days.
            </Typography>
            <Typography variant='body1' style={{ margin: '20px 0' }}>
                Redirecting you to login page in {timer} seconds...
            </Typography>
            <Button onClick={() => navigate('/dashboard')} variant='contained' color='primary' sx={{ marginBottom: 5 }}>
                Go to Login
            </Button>
        </Container>
    );
}

export default PaymentFailed;