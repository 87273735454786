import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function PaymentWaitingScreen() {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(60);

    useEffect(() => {
        if (timer === 0) {
            navigate('/login');
        }

        const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer, navigate]);

    return (
        <Container maxWidth='sm' style={{ textAlign: 'center', marginTop: '50px' }}>
            <CircularProgress style={{ fontSize: 80, color: 'orange' }} />
            <Typography variant='h4' style={{ marginTop: '20px' }}>
                Payment Pending
            </Typography>
            <Typography variant='body1' style={{ margin: '20px 0' }}>
                It will take 20 minute to reconcile
            </Typography>
            <Typography variant='body1' style={{ margin: '20px 0' }}>
                Please use the same mobile number to login and view your card. Your card will be available in My Cards section.
            </Typography>
            <Typography variant='body1' style={{ margin: '20px 0' }}>
                Redirecting you to login page in {timer} seconds...
            </Typography>
            <Button onClick={() => navigate('/dashboard')} variant='contained' color='primary' sx={{ marginBottom: 5 }}>
                Go to Login
            </Button>
        </Container>
    );
}

export default PaymentWaitingScreen;