import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent,Typography, Button, Box, IconButton } from '@mui/material';

import LinearProgress from '@mui/material/LinearProgress';
import lock_1a from '../../assets/icons/lock_1a.png';
import ProcessingDialog from '../orders/ProgressingDialog';
import { viewDigitalInvitationCardData } from '../../redux/actions/digitalCardActions';


const PublicPageForDigitalInvitationView = () => {

    
    const [loading, setLoading] = useState(true);
 
    const { order_id } = useParams();
   
    const [openDialog, setOpenDialog] = useState(false);
    const {invitation_id} = useParams();
    // if (loading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error}</div>;




    const [viewDigitalCard, setViewDigitalCard] = useState();
    useEffect(() => {
        const ViewDigitalCard = async () => {
            const id = { invitation_id };
            setLoading(true);
            const data = await viewDigitalInvitationCardData(id);
            setViewDigitalCard(data?.data?.data);
            setLoading(false)

        };
        ViewDigitalCard();
    }, [order_id,invitation_id]);


    const [timer, setTimer] = useState(90);
    useEffect(() => {

        if (timer === 0) {
            window.location.reload();
        }
        const interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000 * 1);
        return () => clearInterval(interval);
    }, [timer]);


    const handleDownload = async (card) => {
        if (!card?.final_url) return;

        try {
            const response = await fetch(card.final_url);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const urlParts = card.final_url.split('.');
            const extension = urlParts[urlParts.length - 1];

            const filename = (card.title).split("::")[0] + `_final.${extension}`;

            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download failed:", error);
        }
    };

  

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4, mb: 5 }}>
                <CardContent>

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* <Typography variant='h6'>Hi {payment_data?.customer_name},</Typography> */}
                        <Typography variant='h6'>
                            Hi <Box component="span" sx={{ color: 'green', fontWeight: 'bold' }}>{viewDigitalCard?.attendee_name}</Box>,
                        </Typography>
                        <Typography sx={{ color: '#757575', fontSize: '0.825rem' }}>Reloading in {timer} seconds...</Typography>

                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography variant='body1'>Congratulations! You've received an e-invite from {viewDigitalCard?.rsvp}. Below are the details of your invitation. Please click the download button to access your e-invite.</Typography>
                    </Box>

                    <Box>
                        {viewDigitalCard ? (
                            <>
                                {viewDigitalCard.thumbnail ? (
                                    <>
                                        <div style={{ position: 'relative', textAlign: 'center' }}>
                                            <img
                                                src={viewDigitalCard.thumbnail}
                                                alt={viewDigitalCard.title}
                                                style={{ width: '60%', height: 'auto' }}
                                            />
                                            {viewDigitalCard.isLocked && (
                                                <IconButton
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50px',
                                                        transform: 'translate(-50%, -50%)',
                                                        color: '#616161',
                                                        fontSize: 1,
                                                    }}
                                                >
                                                    <img
                                                        src={lock_1a}
                                                        style={{ fontSize: 'inherit', width: '50px', height: '50px' }}
                                                        alt='lock 1a'
                                                    />
                                                </IconButton>
                                            )}
                                        </div>

                                        <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'row' }, alignItems: 'flex-end', gap: 1 }}>
                                            <>
                                                {viewDigitalCard?.final_url && (
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={viewDigitalCard.isLocked}
                                                        sx={{ textTransform: 'none' }}
                                                        onClick={() => {
                                                            const link = document.createElement('a');
                                                            link.href = viewDigitalCard.final_url;
                                                            link.download = viewDigitalCard.title || 'download';
                                                            link.target = '_blank';
                                                            link.click();
                                                        }}
                                                    >
                                                        View E-invite
                                                    </Button>
                                                )}
                                                {viewDigitalCard?.final_url && (
                                                    <Button
                                                        variant='outlined'
                                                        disabled={viewDigitalCard.isLocked}
                                                        onClick={() => handleDownload(viewDigitalCard)}
                                                        sx={{ textTransform: 'none' }}
                                                    >
                                                        Download E-invite
                                                    </Button>
                                                )}
                                            </>
                                        </Box>
                                    </>

                                ) : (
                                    <>
                                        <LinearProgress />
                                        {viewDigitalCard?.estimated_minutes_to_complete && <Typography>It will take few more minute to complete</Typography>}
                                    </>

                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </Box>
                </CardContent>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>


                </Box>


                <ProcessingDialog open={openDialog} onClose={() => setOpenDialog(false)} />
            </Card>
        </div>
    );
};

export default PublicPageForDigitalInvitationView;