import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ProcessingDialog = ({ open, onClose }) => {
    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                onClose();
            }, 10000); // Auto-close after 10 seconds

            return () => clearTimeout(timer);
        }
    }, [open, onClose]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Notification!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You request is being processed. Please wait...
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default ProcessingDialog;