import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardActions, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { bundlePaymentSuccess, getBundlesData } from '../../redux/actions/bundleActions';
import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import lock_1a from '../../assets/icons/lock_1a.png';
import lock_1b from '../../assets/icons/lock_1b.png';
import ProcessingDialog from './ProgressingDialog';

const PublicPageForOrders = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const { order_id } = useParams();
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);

    //if (loading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error}</div>;

    const bundle = useSelector((state) => state?.bundleData?.bundlesData?.data?.rows);
    const payment_data = useSelector((state) => state?.bundleData?.bundlesData?.bundle_data);


    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function initiateRazorPayPayment(amount, currency, order_name, order_description, rzpOrderId, rzpKey, customer_name, customer_email, customer_mobile, entry_id) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }


        const options = {
            key: rzpKey, // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: order_name,
            description: order_description,
            // image: { logo },
            order_id: rzpOrderId,
            handler: async function (response) {
                console.log("Razorpay Response", response);
                const data = {
                    entry_id: entry_id,
                    orderCreationId: rzpOrderId,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    bundle_id: order_id
                };

                // let paymentReconcileData = {}
                let paymentReconcileData = await bundlePaymentSuccess(data);


                console.log("Payment Success", data);
                if (paymentReconcileData && paymentReconcileData.data) {
                    switch (paymentReconcileData.data.action) {
                        case 'payment_success_screen':
                            navigate(`/payment-successful/${order_id}`);
                            break;
                        case 'payment_waiting_screen':
                            /** Navigate to payment waiting screen*/
                            navigate(`/payment-waiting/${order_id}`);
                            break;
                        case 'payment_failed_screen':
                            /**navigate to payment failed screen */
                            navigate(`/payment-failed/${order_id}`);
                            break;
                        default:
                            navigate(`/payment-failed/${order_id}`);
                            break;
                    }
                }
            },
            prefill: {
                name: customer_name,
                email: customer_email,
                contact: customer_mobile,
            },
            // notes: {
            //   address: "Soumya Dey Corporate Office",
            // },
            theme: {
                color: "#61dafb",
            },
            "modal": {
                "ondismiss": function () {
                    navigate(`/payment-failed/${order_id}`);
                }
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    /**
     * 
    let payment_data = {
    "order_id": null,
    "amount": 11,
    "original_amount": 249,
    "customer_mobile": "9450162895",
    "customer_name": "Indrajeet Singh",
    "customer_email": "adeupm@gmail.com",
    "payment_req_object": {
        "status": "success",
        "message": "Order created successfully",
        "data": {
            "orderId": "order_PJb9bhTWdI1mO2",
            "amount": 1100,
            "currency": "INR",
            "receipt": "744d1f69-f9b1-4acd-b639-97a268016b92",
            "status": "created",
            "attempts": 0,
            "created_at": 1731240871,
            "offer_id": null
        },
        "key": "rzp_live_L3aKyZWl8SRyYR"
    }
}
     */

    const initiatePaymentSteps = (payment_data) => {
        // payment_data = JSON.parse(payment_data);



        initiateRazorPayPayment(
            payment_data.payment_req_object.data.amount,
            payment_data.payment_req_object.data.currency,
            "E-invite",
            "Unlock E-invite",
            payment_data.payment_req_object.data.orderId,
            payment_data.payment_req_object.data.key,
            payment_data.customer_name,
            payment_data.customer_email,
            payment_data.customer_mobile,
            order_id
        )
    };

    useEffect(() => {
        const fetchBundleData = async () => {
            const id = { bundle_id: order_id };
            setLoading(true);
            await dispatch(getBundlesData(id));
            setLoading(false)

        };
        fetchBundleData();
    }, [order_id, dispatch]);


    const [timer, setTimer] = useState(30);
    useEffect(() => {

        if (timer === 0) {
            window.location.reload();
        }
        const interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000 * 1);
        return () => clearInterval(interval);
    }, [timer]);

    const handleDownloadAll = async () => {
        await setOpenDialog(true);
        await bundle.forEach(product => {
            if (product.final_url) {
                handleDownload(product);
            }
        });
        await setOpenDialog(false);
    };

    const handleDownload = async (card) => {
        if (!card?.final_url) return;

        try {
            const response = await fetch(card.final_url);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const urlParts = card.final_url.split('.');
            const extension = urlParts[urlParts.length - 1];

            const filename = (card.title).split("::")[1] + `_final.${extension}`;

            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download failed:", error);
        }
    };

    const getTitle = (title) => {
        return title.split("::")[1];
    }

    return (


        <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4, mb: 5 }}>
            <CardContent>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {/* <Typography variant='h6'>Hi {payment_data?.customer_name},</Typography> */}
                    <Typography variant='h6'>
                        Hi <Box component="span" sx={{ color: 'green', fontWeight: 'bold' }}>{payment_data?.customer_name}</Box>,
                    </Typography>
                    <Typography sx={{ color: '#757575', fontSize: '0.825rem' }}>Reloading in {timer} seconds...</Typography>

                </Box>

                {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ?
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography variant='body1'>Congratulations! Your e-invite has been successfully generated. Below are your e-invites you can View and Download it.</Typography>

                        </Box>
                    </> :
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography variant='body1'>Congratulations! Your e-invite has been successfully generated. Below are samples from the original invite. Please make a payment to unlock and access the full original e-invite.</Typography>
                        </Box>
                    </>
                }


                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>E-invite</TableCell>

                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <Typography>Please wait, while fetching data</Typography>
                        ) : (
                            <TableBody>
                                {bundle?.map((product, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {/* Display the thumbnail image */}
                                            {product.thumbnail ? (
                                                <>
                                                    <div style={{ position: 'relative' }}>
                                                        <img
                                                            src={product.thumbnail}
                                                            alt={product.title}
                                                            style={{ width: '100px', height: 'auto' }}
                                                        />
                                                        {product.isLocked && (
                                                            <IconButton
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50px',
                                                                    transform: 'translate(-50%, -50%)',
                                                                    color: '#616161',
                                                                    fontSize: 1,
                                                                }}
                                                            >
                                                                <img
                                                                    src={lock_1a}
                                                                    style={{ fontSize: 'inherit', width: '50px', height: '50px' }}
                                                                    alt='lock 1a'
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </div>

                                                    <Box sx={{ display: 'flex', marginTop: 1 }}>
                                                        {product?.isLocked && (
                                                            <>
                                                                <img
                                                                    src={lock_1b}
                                                                    style={{ fontSize: 'inherit', width: '20px', height: '20px' }}
                                                                    alt='lock 1b'
                                                                />

                                                                <Typography variant='body2' sx={{
                                                                    paddingLeft: "10px", color: '#DC7900'
                                                                }}>
                                                                    Pay to Unlock Original E-invite
                                                                </Typography>
                                                            </>
                                                        )}

                                                    </Box>
                                                </>

                                            ) : (
                                                <>
                                                    <LinearProgress />
                                                    {product?.estimated_minutes_to_complete && <Typography>It will take few more minute to complete</Typography>}
                                                </>

                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {/* Button to download the image */}

                                            <Typography>{getTitle(product?.title)}</Typography>
                                            <Typography>{product?.final_mime}</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-end', gap: 1 }}>
                                                <>
                                                    {product?.final_url && (
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            disabled={product.isLocked}
                                                            sx={{ textTransform: 'none' }}
                                                            onClick={() => {
                                                                const link = document.createElement('a');
                                                                link.href = product.final_url;
                                                                link.download = product.title || 'download';
                                                                link.target = '_blank';
                                                                link.click();
                                                            }}
                                                        >
                                                            {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ? "View E-invite" : "View Sample"}
                                                        </Button>
                                                    )}
                                                    {product?.final_url && (
                                                        <Button
                                                            variant='outlined'
                                                            disabled={product.isLocked}
                                                            onClick={() => handleDownload(product)}
                                                            sx={{ textTransform: 'none' }}
                                                        >
                                                            {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ? "Download E-invite" : "Download Sample"}
                                                        </Button>
                                                    )}
                                                </>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}

                    </Table>
                </TableContainer>
            </CardContent>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
                {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ?
                    <>
                        <Typography sx={{ fontWeight: 'bold', color: 'green' }}>Payment: Not needed / Already done</Typography>
                        <Typography variant='body1' sx={{ m: 4, color: 'gray' }}>May your wedding day be filled with love, laughter, and unforgettable memories. As you and your partner begin this new chapter together, I wish you both a lifetime of happiness, understanding, and adventure.
                            Cheers to a wonderful celebration and a blissful marriage ahead!</Typography>
                    </> : <>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 }, marginLeft: 2 }}>
                            <Box
                                bgcolor="#2e7d32"
                                color="white"
                                px={0.5}
                                py={0.5}
                                borderRadius="4px"
                                display="inline-block"
                                sx={{ fontSize: '1rem' }}
                            >
                                {Math.round(((payment_data?.original_amount - payment_data?.amount) / payment_data?.original_amount) * 100)}% off
                            </Box>
                            <Typography sx={{ fontSize: '0.825rem', color: '#2e7d32', fontWeight: 'bold' }}>
                                Dev Diwali Sale
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' }, marginLeft: 2 }}>
                            <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                                {Math.floor(payment_data?.amount) === 0 ? 'Free' : `₹${Math.floor(payment_data?.amount)}`}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                                M.R.P: ₹{payment_data?.original_amount?.toLocaleString()}
                            </Typography>
                        </Box>
                    </>
                }

                {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ? (
                    <></>
                ) : (
                    <>
                        <Button size="large" variant="contained" sx={{ color: 'white', backgroundColor: '#d32f2f', marginTop: 1 }} onClick={() => initiatePaymentSteps(payment_data)}>
                            Pay Now and Unlock All E-invites
                        </Button>

                        <Box sx={{ mt: 5 }}>
                            <Typography sx={{ color: 'gray' }}>If you face any issue while payment you can whatsapp us on +91 87651 40002 </Typography>
                        </Box>
                    </>
                )}
            </Box>

            <CardActions>
                <Button size="small" color="primary" onClick={handleDownloadAll}>
                    {payment_data?.payment_status === 'not_needed' || payment_data?.payment_status === 'captured' ? "Download All E-invite" : "Download All Sample"}
                </Button>
            </CardActions>
            <ProcessingDialog open={openDialog} onClose={() => setOpenDialog(false)} />
        </Card>
    );
};

export default PublicPageForOrders;