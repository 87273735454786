import * as actionTypes from "../actionTypes.js";

const initialState ={
    memberdata: [],
    loading: false,
    error: null,
}

const memberReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_ALL_MEMBERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_ALL_MEMBERS_SUCCESS:
            return{
                ...state,
                memberdata: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.GET_ALL_MEMBERS_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default memberReducer;