import * as actionTypes from "../actionTypes.js";

const initialState ={
    digitalcardData: [],
    loading: false,
    error: null,
}

const digitalCardReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_ALL_DIGITAL_CARDS_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_ALL_DIGITAL_CARDS_SUCCESS:
            return{
                ...state,
                digitalcardData: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.GET_ALL_DIGITAL_CARDS_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.GET_SINGLE_DIGITAL_CARD_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_SINGLE_DIGITAL_CARD_SUCCESS:
            return{
                ...state,
                digitalcardData: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.GET_SINGLE_DIGITAL_CARD_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default digitalCardReducer;

