import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItemButton, ListItemText, Box, MenuItem, Button,} from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StyledMenu from './StyledMenu';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme as useAppTheme } from '../theme/ThemeContext';
import { Logout } from '../redux/actions/authActions';
import { useDispatch } from 'react-redux';




const DrawerComp = ({ openMenu, handleMenuClick, handleClose, menuStates, buttonLabels, menuItems }) => {
    const [open, setOpen] = useState(false);

    console.log("menu item in drawer", menuItems);

    const handleDrawerToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleMenuClose = () => {
        handleClose();
        // Ensure drawer remains open when menu is closed
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogin = () => {
        setOpen(false);
        navigate('/login');
    };

    const navigate = useNavigate();

    const handleNavigation = (path_url) => {
        navigate(`/cards/${path_url}`);
        setOpen(false);
    };
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const { isDarkMode, toggleTheme } = useAppTheme();
    const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const handleAvatarMenuClick = (event) => {
        setAvatarMenuAnchorEl(event.currentTarget);
    };

    const handleAvatarMenuClose = () => {
        setAvatarMenuAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await dispatch(Logout(navigate));
            handleAvatarMenuClose();
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };




    return (
        <>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <List sx={{ width: 250 }}>
                    {buttonLabels.map((label, index) => (
                        <ListItemButton key={index} onClick={(e) => handleMenuClick(e, index + 1)}>
                            <ListItemText primary={label} />
                            <KeyboardArrowDownIcon />
                            <StyledMenu
                                id={`drawer-customized-menu-${index + 1}`}
                                anchorEl={menuStates[index].anchorEl}
                                open={openMenu && menuStates[index].isOpen}
                                onClose={handleMenuClose}
                            >

                                {menuItems[index + 1].map((item, itemIndex) => (
                                    <MenuItem key={itemIndex} onClick={() => handleNavigation(item.path)} disableRipple>
                                        {item.label}
                                    </MenuItem>
                                ))}

                            </StyledMenu>
                        </ListItemButton>
                    ))}
                </List>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    {!isAuthenticated ? (
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: isDarkMode ? '#BB86FC' : 'black'
                            }}
                            onClick={handleLogin}
                        >
                            Login/Register
                        </Button>
                    ) : (
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: isDarkMode ? '#BB86FC' : 'black'
                            }}
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    )}

                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            textTransform: 'none',
                            color: 'white',
                            mt: 2
                        }}
                    >
                        Download App
                    </Button>
                </Box>

            </Drawer>

            <IconButton sx={{ marginLeft: 'auto' }} onClick={handleDrawerToggle}>
                <MenuRoundedIcon />
            </IconButton>
        </>
    );
};

export default DrawerComp;
