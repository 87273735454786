import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, CircularProgress, Container, Typography } from '@mui/material';

function BundlePaymentWaitingScreen() {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(30);
    const { bundle_id } = useParams(); // Destructure bundle_id here

    useEffect(() => {
        if (timer === 0) {
            navigate(`/bundle-order/${bundle_id}`);
        }

        const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(countdown); // Cleanup interval
    }, [timer, navigate, bundle_id]); // Add bundle_id to dependencies
    return (
        <div>
            <Container maxWidth='sm' style={{ textAlign: 'center', marginTop: '50px' }}>
                <CircularProgress style={{ fontSize: 80, color: 'orange' }} />
                <Typography variant='h4' style={{ marginTop: '20px' }}>
                    Payment Pending
                </Typography>
                <Typography variant='body1' style={{ margin: '20px 0' }}>
                    It will take 20 minute to reconcile
                </Typography>
                <Typography variant='body1' style={{ margin: '20px 0' }}>
                    Please use the same mobile number to login and view your card. Your card will be available in My Cards section.
                </Typography>
                <Typography variant='body1' style={{ margin: '20px 0' }}>
                    Redirecting you to login page in {timer} seconds...
                </Typography>
                <Button onClick={() => navigate(`/bundle-order/${bundle_id}`)} variant='contained' color='primary' sx={{ marginBottom: 5 }}>
                    Go to Download Page
                </Button>
            </Container>
        </div>
    )
}

export default BundlePaymentWaitingScreen