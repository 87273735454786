import React, { useEffect, useState } from 'react'
import { Box, Card, CardMedia, CardContent, Typography, Grid, Button, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, TextField, DialogActions, DialogContentText, InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'animate.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme as useAppTheme } from '../../theme/ThemeContext';
import { deleteDigitalCardData, getAllDigitalCardData, sendDigitalInvitationCardData } from '../../redux/actions/digitalCardActions';
import RajOffsetProcessingImage from '../../assets/rajoffset_processing_image.png';
import AddMember from './AddMember';

function DigitalCards() {

    const { isDarkMode } = useAppTheme();

    const digitalCard = useSelector((state) => state?.digitalCards?.digitalcardData?.data);
    console.log("digital card", digitalCard);
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);
    const navigate = useNavigate();


    // fetch data
    // send Digital invitation 
    const [openInvitation, setOpenInvitation] = useState(false);
    const [attendeeName, setAttendeeName] = useState('');
    const [attendeeMobile, setAttendeeMobile] = useState('');
    const [attendeeEmail, setAttendeeEmail] = useState('');
    const [attendeeMessage, setAttendeeMessage] = useState('');
    const [digitalCardId, setDigitalCardId] = useState('');




    useEffect(() => {
        setAttendeeMessage('Invitation with Family');
        const fetchDigitalCard = async () => {
            setloading(true);
            await dispatch(getAllDigitalCardData());
            setloading(false);
        };
        fetchDigitalCard();
    }, [dispatch])

    // menu items 
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const [deleteCardId, setDeleteCardId] = useState();

    // Handle menu open/close
    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setDeleteCardId(id);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setDeleteCardId(null);
    };


    const handleSendInvitation = (id) => {
        setDigitalCardId(id);
        setOpenInvitation(true);
    };
    const handleSendInvitationClose = () => {
        setOpenInvitation(false);
    };
    const handleSendDigitalInvitation = async (e) => {
        e.preventDefault();
        const data = {
            attendee_name: attendeeName.replaceAll("&", "and"),
            attendee_mobile: attendeeMobile,
            attendee_email: attendeeEmail,
            attendee_message: attendeeMessage.replaceAll("&", "and"),
            id: digitalCardId
        };
        setloading(true);
        const response = await sendDigitalInvitationCardData(data);
        console.log("response in digital card", response);
        setloading(false);
        if (response.status === 'success') {
            navigate(`/send-invitation-successfull`);
        }
        handleSendInvitationClose();
    };

    // add member 
    const [openAddMember, setOpenAddMember] = useState(false);

    const handleAddMemberOpen = (id) => {
        setOpenAddMember(true);
        setDigitalCardId(id);
    };
    const closeHandleAddMember = () => {
        setOpenAddMember(false);
    }

    // Delete Card

    const [openDelete, setOpenDelete] = useState(false);


    const handleOpenCardDelete = (card) => {
        setDeleteCardId(card?.id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleDigitalCardDelete = async () => {
        const deletedData = { id: deleteCardId }
        await deleteDigitalCardData(deletedData);
        await dispatch(getAllDigitalCardData());
        handleMenuClose();
        setOpenDelete(false);
    }


    const [emailError, setEmailError] = useState(false);


    const handleTextChange = (e, setState) => {
        const cleanValue = e.target.value.replace(/[^0-9A-Za-z.,()\/\-\s]/g, '');
        setState(cleanValue);
    };


    const handleMobileChange = (e, setState) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setState(value.slice(0, 10)); // Limit to 10 digits
    };


    const validateEmail = (e) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailError(!emailPattern.test(e.target.value));
    };


    return (
        <div>
            <Typography variant="h6" sx={{ margin: '5px', textAlign: 'left', fontWeight: 800, marginLeft: 1.5 }}>
                Digital E-invite Cards
            </Typography>
            <Grid
                container
                spacing={2}
                style={{ padding: 5 }}
            >
                {digitalCard?.rows?.length > 0 ? (
                    digitalCard.rows.map((card, index) => (
                        <Grid item xs={12} md={12} key={index}>
                            <Card sx={{ backgroundColor: '#f8f4ff', }}>
                                <CardContent>
                                    <Grid item xs={12} md={12} lg={12} key={index}>

                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            {/* Content and Button Box */}
                                            <Grid item xs={8} >

                                                <Typography variant="body2" color="textSecondary">
                                                    #{card?.id?.slice(0, 8)}
                                                </Typography>

                                                <Typography variant="h7" gutterBottom>
                                                    {card?.title}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" paragraph>
                                                    {card?.entry_associated_card?.card_description}
                                                </Typography>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
                                                    <Typography
                                                        sx={{
                                                            backgroundColor: '#ffffff',
                                                            color: 'black',
                                                            borderRadius: '5px',
                                                            padding: '5px 5px',
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                        }}
                                                    >
                                                        {card?.status === 'ready' ? (
                                                            'Your E-invite is Ready to send'
                                                        ) : 'Estimated time to process is 30 minutes'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3.5} >

                                                {card?.thumbnail ? <>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ height: '180px', objectFit: 'contain' }}
                                                        image={card?.thumbnail}
                                                        alt=""
                                                    />
                                                </> : <>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ height: '180px', objectFit: 'contain' }}
                                                        image={RajOffsetProcessingImage}
                                                        alt=""
                                                    />
                                                </>}



                                            </Grid>
                                            <Grid item xs={0.5}  >
                                                <IconButton onClick={(event) => handleMenuClick(event, card.id)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={menuOpen && deleteCardId === card.id}
                                                    onClose={handleMenuClose}
                                                >

                                                    <MenuItem onClick={() => handleOpenCardDelete(card)}>Delete</MenuItem>

                                                </Menu>
                                            </Grid>

                                        </Box>


                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} key={index} sx={{ padding: 1 }}>


                                        <Box sx={{ marginTop: 0, display: 'flex', justifyContent: 'space-around' }}>
                                            {card?.edit_counter > 0 ? (
                                                <Button
                                                    variant='outlined'
                                                    sx={{

                                                        borderRadius: '15px',
                                                        padding: '5px 15px',
                                                        fontWeight: 'bold',

                                                        textAlign: 'center',
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={() => navigate(`/dashboard/digital-cards/edit-digital-card/${card?.id}`)}
                                                >
                                                    Edit Card
                                                </Button>
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        marginRight: '10px',
                                                    }}

                                                >
                                                    <span role="img" aria-label="lock">
                                                        🔒
                                                    </span>
                                                    <span style={{ marginRight: '5px' }}>Edit not allowed</span>

                                                </div>
                                            )}




                                            {card?.payment_status == 'Pending' && (
                                                <Button
                                                    sx={{
                                                        backgroundColor: 'pink',
                                                        color: 'black',
                                                        borderRadius: '15px',
                                                        padding: '5px 15px',
                                                        fontWeight: 'bold',
                                                        '&:hover': { backgroundColor: '#fbc02d' },
                                                        textAlign: 'center',
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    Pay Now
                                                </Button>
                                            )}

                                            <Button variant='outlined'
                                                sx={{
                                                    borderRadius: '15px',
                                                    padding: '5px 15px',
                                                    fontWeight: 'bold',
                                                    '&:hover': { backgroundColor: '#fbc02d' },
                                                    textAlign: 'center',
                                                }}
                                                onClick={() => handleAddMemberOpen(card?.id)}
                                            >
                                                Add member
                                            </Button>
                                            {card?.final_url && (
                                                <Button
                                                    variant='outlined'
                                                    sx={{


                                                        borderRadius: '15px',
                                                        padding: '5px 15px',
                                                        fontWeight: 'bold',
                                                        '&:hover': { backgroundColor: '#fbc02d' },
                                                        textAlign: 'center',
                                                    }}
                                                    onClick={() => {
                                                        const link = document.createElement('a');
                                                        link.href = card.final_url;
                                                        link.target = '_blank';
                                                        link.download = 'file.pdf'; // Optional: specify a default filename
                                                        document.body.appendChild(link);
                                                        link.click();
                                                        document.body.removeChild(link);
                                                    }}
                                                >
                                                    Download Now
                                                </Button>
                                            )}


                                        </Box>
                                    </Grid>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', borderRadius: '20px', flexDirection: 'row', gap: 2 }}>
                                        <Button variant='contained' onClick={() => handleSendInvitation(card?.id)}>Send Digital Invitation</Button>

                                    </Box>

                                </CardContent>
                                <Typography
                                    sx={{
                                        textAlign: "right",
                                        fontStyle: "italic",
                                        marginTop: "8px",
                                        fontSize: "0.65rem",
                                    }}
                                >
                                    <span style={{ color: "#616161" }}>Created at: </span>
                                    {new Date(card?.createdAt).toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                    })}{" "}
                                    {new Date(card?.createdAt).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                    })}

                                    &nbsp;&nbsp;&nbsp;
                                    <span style={{ color: "#616161" }}>Updated at: </span>
                                    {new Date(card?.updatedAt).toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                    })}{" "}
                                    {new Date(card?.updatedAt).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                    })}
                                </Typography>


                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography sx={{ marginTop: 5, marginLeft: 5 }}>No Product available</Typography>
                    </Grid>
                )}
            </Grid>

            <Dialog open={openInvitation} onClose={handleSendInvitationClose}>
                <DialogTitle style={{ textAlign: 'center' }}>Send Digital Invitation</DialogTitle>
                <Typography variant='body2' style={{ textAlign: 'center', paddingLeft: '2px', paddingRight: "2px" }}>Attendee Will Receive E-invite on WhatsApp, SMS and remainder through phone call. Please Type Attendee Name and Attendee Number carefully as Attendee Name will be mentioned on E-invite And Notification will be received on Attendee Number.</Typography>
                <DialogContent>
                    <form onSubmit={handleSendDigitalInvitation}>
                        <TextField
                            label="Attendee Name*"
                            value={attendeeName}
                            onChange={(e) => handleTextChange(e, setAttendeeName)}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Attendee WhatsApp Number Only*"
                            value={attendeeMobile}
                            onChange={(e) => handleMobileChange(e, setAttendeeMobile)}
                            fullWidth
                            margin="normal"
                            helperText={attendeeMobile.length > 0 && attendeeMobile.length !== 10 ? "Mobile number must be 10 digits" : ""}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                                            alt="Indian Flag"
                                            style={{ width: '24px', height: '16px', marginRight: '8px' }}
                                        />
                                        <span>+91</span>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Attendee Email (optional)"
                            value={attendeeEmail}
                            onChange={(e) => setAttendeeEmail(e.target.value)}
                            onBlur={validateEmail} // Check email format on blur
                            fullWidth
                            margin="normal"
                            helperText={emailError ? "Enter a valid email address" : ""}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Attendee Message*"
                            value={attendeeMessage}
                            onChange={(e) => handleTextChange(e, setAttendeeMessage)}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />
                        <DialogActions>
                            <Button onClick={handleSendInvitationClose} color="primary">Cancel</Button>
                            <Button variant='outlined' type="submit" color="primary" >Send E-invite</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>


            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogTitle>Delete Digital Card</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Card
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDigitalCardDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <AddMember
                open={openAddMember}
                handleClose={closeHandleAddMember}
                card={digitalCardId}
            />
        </div>
    )
}

export default DigitalCards