import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, Grid, Divider, useMediaQuery, ListItemButton, IconButton, Drawer, ListItemIcon, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Rsvp } from '@mui/icons-material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const categories = [
  // { label: 'Dashboard', path: '/dashboard/complete-profile', icon: 'home' },
  { label: 'My saved Cards', path: '/dashboard/my-cards', icon: 'HistoryEduIcon' },
  { label: 'Digital E-invites', path: '/dashboard/digital-cards', icon: 'rsvp' },
  { label: 'My Profile', path: '/dashboard/my-profile', icon: 'AccountCircleIcon' },
  { label: 'Invoices', path: '/dashboard/invoices', icon: 'ReceiptIcon' },
  { label: 'Support Ticket', path: '/support-ticket', icon: 'SupportAgentIcon' },
];

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptopOrAbove = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);


  const handleMobileDrawerToggle = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };


  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeDrawerAndNavigate = (path) => {
    if (isMobile) {
      setIsMobileSidebarOpen(false);
    }
    navigate(path);
  };

  const getIcon = (label) => {
    switch (label) {
      case 'home':
        return <HomeIcon />;
      case 'rsvp':
        return <Rsvp />;
      case 'HistoryEduIcon':
        return <HistoryEduIcon />;
      case 'AccountCircleIcon':
        return <AccountCircleIcon />;
      case 'ReceiptIcon':
        return <ReceiptIcon />;
      case 'SupportAgentIcon':
        return <SupportAgentIcon />;
      case 'AccountCircleIcon':
        return <AccountCircleIcon />;
      case 'AccountCircleIcon':
        return <AccountCircleIcon />;
      case 'AccountCircleIcon':
        return <AccountCircleIcon />;
      case 'AccountCircleIcon':
        return <AccountCircleIcon />;
      default:
        return <CategoryIcon />;
    }
  };


  const sidebarContent = (
    <List>
      {categories.map((category, index) => (
        <React.Fragment key={index}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => closeDrawerAndNavigate(category.path)}>
              <ListItemIcon>
                {getIcon(category.icon)}
              </ListItemIcon>
              <ListItemText sx={{ color: '#616161' }} primary={category.label} />
            </ListItemButton>
          </ListItem>
          <Divider sx={{ borderStyle: 'dotted', borderColor: '#aaa', borderWidth: '0 0 1px 0' }} />
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <Box sx={{ display: 'flex', overflowX: 'hidden', padding: 0, marginTop: 1 }}>
      {/* Mobile Layout */}
      {isMobile ? (
        <Grid container spacing={0} sx={{ marginX: 'auto', width: '100%', maxWidth: '100%' }}>
          <Grid
            item
            xs={12}
            sm={10}
            sx={{
              padding: '4px',
              minHeight: '100vh',
            }}
          >
            <IconButton
              size='small'
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleMobileDrawerToggle}
              sx={{ ml: 1, display: { sm: 'none' }, border: '1px solid #000', borderRadius: '5px' }}
            >
              <MenuIcon /> &nbsp; Open Menu
            </IconButton>

            {/* Mobile Sidebar Drawer */}
            <Drawer
              variant="temporary"
              anchor="left"
              open={isMobileSidebarOpen}
              onClose={handleMobileDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
              }}
            >
              {sidebarContent}
            </Drawer>

            <Outlet />
          </Grid>
        </Grid>
      ) : (
        // Laptop/Desktop Layout
        <Grid container spacing={0} sx={{ marginX: 'auto', width: '100%', maxWidth: '100%' }}>
          {/* Sidebar for laptop and larger */}
          {isLaptopOrAbove && (
            <Grid
              item
              xs={12}
              sm={isSidebarOpen ? 2 : 'auto'}
              sx={{
                borderRadius: '10px',
                overflow: 'hidden',
                padding: '5px 0',
                backgroundColor: '#f8f4ff',
                height: '95vh',
                position: 'sticky',
                top: 0,
                display: isSidebarOpen ? 'block' : 'none',
              }}
            >
              {sidebarContent}
            </Grid>
          )}

          {/* Main content area */}
          <Grid
            item
            xs={12}
            sm={isSidebarOpen ? 10 : 12}
            sx={{
              padding: '2px',
              marginRight: 0,
              minHeight: '100vh',
            }}
          >
            <Box sx={{
              marginLeft: '5px'
            }}>
              <Outlet />
            </Box>

          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Dashboard;
