import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import authReducer from "./authReducers";
import faqReducer from "./faqReducers";
import cardForCustomerReducer from "./cardsForCustomerReducer";
import getAllCardSuggestionReducer from "./getAllCardSuggestionReducers";
import allCardsDataReducer from "./allCardsDataReducers";
import multiProfileReducers from "./multiProfileReducers";
import bundleReducer from "./bundleReducers";
import digitalCardReducer from "./digitalCardReducers";
import memberReducer from "./memberReducers";

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    auth: authReducer,
    faq: faqReducer,
    cardforCustomer: cardForCustomerReducer,
    getAllCardSuggestions: getAllCardSuggestionReducer,
    cardData: allCardsDataReducer,
    multiProfile: multiProfileReducers,
    bundleData: bundleReducer,
    digitalCards: digitalCardReducer,
    membersData: memberReducer,
});
export default persistReducer(persistConfig, rootReducer);
