import React, { useState, useEffect } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SendInvitationSuccessfull = () => {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(30);

    useEffect(() => {
        if (timer === 0) {
            navigate('/dashboard/my-cards');
        }

        const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer, navigate]);
    return (
        <div>
            <Container maxWidth='sm' style={{ textAlign: 'center', marginTop: '50px' }}>
                <CheckCircleOutlineIcon style={{ fontSize: 80, color: 'green' }} />
                <Typography variant='h4' style={{ marginTop: '20px' }}>
                Digital Invitation Sent Successfully! Your e-invite is being processed and will be delivered shortly.
                </Typography>
                <Typography variant='body1' style={{ margin: '20px 0' }}>
                    Your Attendee Will get Invitation Notification on WhatsApp soon. Expect delivery within 30 minutes.
                    The Invitation card will also be available in your My Cards section so that you can download/share it with others manually.
                </Typography>
                <Typography variant='body1' style={{ margin: '20px 0' }}>
                    Redirecting you to My Cards page in {timer} seconds...
                </Typography>
                <Button onClick={() => navigate('/dashboard/my-cards')} variant='contained' color='primary' sx={{ marginBottom: 5 }}>
                    Go to My Cards
                </Button>
            </Container>
        </div>
    )
}

export default SendInvitationSuccessfull