import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getAllMembersRequest = () => ({
    type: actionTypes.GET_ALL_MEMBERS_REQUEST,
});

export const getAllMembersSuccess = (data) => ({
    type: actionTypes.GET_ALL_MEMBERS_SUCCESS,
    payload: data,
});

export const getAllMembersFailure = (error) => ({
    type: actionTypes.GET_ALL_MEMBERS_FAILURE,
    payload: error,
});

export const getAllMembersData = (id) => {
    return async (dispatch) => {
        dispatch(getAllMembersRequest());
        try{
            const data = await api.getAllMembers(id);
            console.log("member data in action", data);
            dispatch(getAllMembersSuccess(data));
            return data;
        }catch (error){
            dispatch(getAllMembersFailure(error));
        }
    };
};

export const addMembersData = async (id, formData) => {
    const data = await api.addMember(id, formData);
    return data;
} 